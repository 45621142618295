<template>
    <div class="box">
        <div class="box-header">
            <h3 class="box-title">Seznam operací</h3>
        </div>
        <div class="box-body">
            <div class="row">
                <div class="col-lg-4">
                    <div class="input-group input-group-sm" style="width: 100%">
                        <input v-model="filter" type="text" name="table_search" class="form-control" placeholder="Hledat">
                    </div>
                </div>
                <div class="col-lg-8">
                    <router-link :to="{ name: 'OperationAdd' }" class="btn btn-default btn-flat btn-sm pull-right"><i class="fa fa-plus"></i> Přidat operaci</router-link>
                    <button class="btn btn-default btn-flat btn-sm pull-right mr-2" @click="deleteOperations()"><i class="fa fa-trash"></i> Odstranit vybrané {{selectedOperations.length > 1 ? "(" + selectedOperations.length + ")" : ""}}</button>
                </div>
            </div>
            <br>
            <div class="table-responsive no-padding" style="width: auto; overflow: auto">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th style="width: 64px"><input type="checkbox" v-model="selectAll"> Vše</th>
                        <th v-for="head in this.headColumns" :key="head.title">{{head.name}}</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="operation in getOperations" :key="operation.id">
                            <td><input type="checkbox" v-model="selectedOperations" :value="operation.id" number></td>
                            <td style="cursor: pointer" @click="makeCurrentListOperation(operation.id)"><a>{{operation.description}}</a></td>
                            <td>{{operation.type == 0 ? "Práce" : "Materiál"}}</td>
                            <td>{{operation.invoice_price}}</td>
                            <td>{{operation.dph}}</td>
                            <td>{{operation.amount}}</td>
                            <td>{{operation.price}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-if="!loaded" class="overlay">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
    </div>
</template>

<script>
const name = 'Operations';

import { mapGetters, mapActions } from "vuex";

export default {
    name,
    data() {
        return {
            filter: '',
            selectedOperations: [],
            loaded: false,
        };
    },
    computed: {
        ...mapGetters(['getAllPages', 'allListOperations', 'getCurrentPage']),
        selectAll: {
            get: function () {
                return this.allListOperations ? this.selectedOperations.length == this.allListOperations.length : false;
            },
            set: function (value) {
                var selectedOperations = [];

                if (value) {
                    this.allListOperations.filter(item => {
                        return (
                            this.$helpers.filterCheck(this.filter, item.description)
                            || this.$helpers.filterCheck(this.filter, String(item.dph))
                        );
                    }).forEach(function (operation) {
                        selectedOperations.push(operation.id);
                    });
                }

                this.selectedOperations = selectedOperations;
            }
        },
        getOperations() {
            var allOperations = this.allListOperations

            if (!allOperations.length)
            {
                return []
            }
            
            // Filtr provedeme na vícero položkách auta -> možnost vyhledávání jak podle jména, tak podle VIN, atd..
            return allOperations.filter(item => {
                return (
                    this.$helpers.filterCheck(this.filter, item.description)
                    || this.$helpers.filterCheck(this.filter, String(item.dph))
                );
            })
        }
    },
    methods: {
        ...mapActions([
            'makeActiveTab', 'addTab', 'fetchListOperations', 
            'makeCurrentCus', 'makeCurrentMnt', 'makeCurrentIssue',
            'makeCurrentListOperation', 'deleteOperation',
        ]),
        deleteOperations() {
            if(this.selectedOperations.length == 0) {
                this.$alerts.message('Nejdříve musíte vybrat alespoň jednu operaci!', 'Výběr provedete pomocí zaškrtávacího políčka vedle názvu operace.', 'error');
            } else {
                this.$alerts.confirmDelete(this.selectedOperations.length > 1 ?  'Opravdu chcete tyto záznamy smazat?' : 'Opravdu chcete tento záznam smazat?', this.selectedOperations.length > 1 ? "Dojde ke smazání celkem " + this.selectedOperations.length + " záznamů." : "").then((result) => {
                    if (result.value) {
                        this.selectedOperations.forEach(id => {
                            this.deleteOperation(id)
                        });
                        this.selectedOperations = []
                    }
                });
            } 
        },
    },
    created() {
        this.fetchListOperations().then(() => {
            this.loaded = true;
        });

        // We put constants to created() method, putting them into data() would make them reactive (to state -> useless..)
        this.headColumns = [
            { name: 'Popis' },
            { name: 'Typ' },
            { name: 'Fakturační cena za 1 hod bez DPH' },
            { name: 'DPH' },
            { name: 'Doba trvání operace v hod/Množství' },
            { name: 'Nákupní cena' }
        ]

        const page = this.getAllPages.filter(page => page.name == name)[0]   
        this.addTab(page);
        this.makeActiveTab(page);
    },
}
</script>